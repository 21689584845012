import {useUserData} from "../components/Hooks/useUserData";

const acceptableUsernames = new Set<string>([
    "cdc5d8e5-da3a-442d-af1f-998ffa49a453", // Matt
    "d72f6530-c7cd-4258-99b2-a4a661752991", // Kirstie
    "0b1ccc93-25d4-4cbd-8cd2-9b236d04a86e", // Sam
    "992ea319-2537-4825-984a-0fa00d8ea2f3", // Tom
    "e9570b69-8a97-4e48-a843-739539c10e26", // Dan
    "43261ef3-706d-48f0-968f-564fa21367da" // Ben
]);

export function useIsPrivilegedUser() {
    const user = useUserData();
    return acceptableUsernames.has(user.username);
}
