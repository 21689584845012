import React, {useEffect} from "react";
import {Venue} from "../../../api/grs";
import Select, {SingleValue} from "react-select";
import {BaseDropdownPropsSingle, DropdownOption} from "../Types/types";
import {useDropdownSingle} from "../Hooks/useDropdown/useDropdownSingle";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {nullifyVenueStore, setVenue} from "../../../store/venue/actions/VenueActions";

/** Dropdown for Venues */
const VenueDropdown = ({venueId, onChange, ...rest}: PulseVenueDropdown) => {
    const store = useSelector((state: RootStore) => state.venueList);
    const dispatch = useDispatch();
    const {options, selectedOption, handleDropdownChange} = useDropdownSingle<Venue>({
        storeName: "venueList",
        onChange,
        id: venueId,
        storeKeyLabel: "name",
        storeKeyValue: "id"
    });
    const venueChange = (newValue: SingleValue<DropdownOption>) => {
        handleDropdownChange(newValue);
        if (!newValue || !newValue.value) {
            dispatch(nullifyVenueStore());
            return;
        }

        const venues = store.data;
        if (!venues || venues.length === 0) return;
        const venue = venues.find((x) => x.id === newValue.value);
        if (!venue) {
            dispatch(nullifyVenueStore());
            return;
        }

        dispatch(setVenue(venue));
    };

    // Load selected venue
    useEffect(() => {
        if (!selectedOption?.value) return;

        const venues = store.data;
        if (!venues || venues.length === 0) return;
        const venue = venues.find((x) => x.id === selectedOption.value);
        if (!venue) {
            dispatch(nullifyVenueStore());
            return;
        }

        dispatch(setVenue(venue));
    }, [dispatch, selectedOption, store.data]);

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={venueChange}
                isSearchable={rest.searchable}
                value={selectedOption}
                noOptionsMessage={() => rest.noItemsMessage}
                placeholder={rest.placeholder}
                isDisabled={rest.disabled}
                isClearable={rest.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default VenueDropdown;

export interface PulseVenueDropdown extends BaseDropdownPropsSingle<Venue> {
    venueId?: number;
}
