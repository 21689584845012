import {ClinicalGrade, UserData} from "../../../../../api/staff";
import moment, {Moment} from "moment";
import {
    AssignmentState,
    AvailableShiftCalendarEntry,
    AvailableShiftGrouping,
    AvailableShiftSection,
    AvailableShiftsRequestUi,
    CalendarTypeEnum
} from "../../../../../api/grs";
import {CalendarEventItem} from "../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {greenGradient, redGradient, yellowGradient} from "../../../../../utils/colourUtils";
import {getTimeTextForEntry} from "../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActions";
import {formatUnixToHHmmddd} from "../../../../../utils/momentUtils";

/** Used to colour the tab on the available shifts calendar  */
export function getAvailableShiftsCalendarTabColour(entry: AvailableShiftCalendarEntry) {
    let totalSlotsTaken = 0;
    let totalAvailableSlots = 0;

    for (const section of entry.requiredStaff) {
        for (const group of section.groupings) {
            totalSlotsTaken += group.takenSlotCount;
            totalAvailableSlots += group.availableSlotCount;
        }
    }

    const endOfDay = moment.unix(entry.startDate).endOf("day").unix();
    const startOfCurrentDay = moment
        .unix(new Date().getTime() / 1000)
        .startOf("day")
        .unix();

    if (startOfCurrentDay > endOfDay) {
        return greenGradient;
    }

    // No staff are signed onto the event. Urgent (red) action is needed
    if (totalSlotsTaken === 0) {
        return redGradient;
    }

    // Staff are assigned onto the event, but there is still space
    if (totalSlotsTaken > 0 && totalAvailableSlots > 0) {
        return yellowGradient;
    }

    // If available slots == 0, means required staff is filled for that event.
    return greenGradient;
}

export function getTitleForAvailableShiftsCalendarTab(entry: AvailableShiftCalendarEntry) {
    switch (entry.calendarType) {
        case CalendarTypeEnum.Frontline:
        case CalendarTypeEnum.OnCall:
            return `${entry.description} ${formatUnixToHHmmddd(
                entry.startDate
            )} - ${formatUnixToHHmmddd(entry.endDate)}`;
        case CalendarTypeEnum.Event:
            return entry.venueName;
    }
}

export function sortEventsForAvailableShiftsCalendar(
    entries: AvailableShiftCalendarEntry[],
    user: UserData
): CalendarEventItem[] {
    const items: CalendarEventItem[] = [];

    for (const entry of entries) {
        const canUserSeeEntry = canUserSeeAvailableShiftsEntry(
            user.clinicalGrade,
            entry.requiredStaff
        );

        if (!canUserSeeEntry) continue;

        const endOfDay = moment.unix(entry.startDate).endOf("day").unix();
        const startOfCurrentDay = moment
            .unix(new Date().getTime() / 1000)
            .startOf("day")
            .unix();

        //We don't want to show historic events on this calendar.
        if (startOfCurrentDay > endOfDay) {
            continue;
        }

        const eventColour = getAvailableShiftsCalendarTabColour(entry);
        const title = getTitleForAvailableShiftsCalendarTab(entry);
        const actualStartDate = new Date(entry.startDate * 1000);
        const actualEndDate = new Date(entry.endDate * 1000);

        const args: CalendarEventItem = {
            textColor: "#eeeeee",
            title,
            start: actualStartDate,
            end: actualEndDate,
            allDay: false,
            id: entry.id.toString(),
            color: eventColour,
            groupId: entry.calendarId.toString(),
            timeText: getTimeTextForEntry(entry.startDate, entry.endDate),
            entryState: AssignmentState.Unassigned,
            assignments: [],
            calendarType: CalendarTypeEnum.Event,
            isHistoric: false //Available shifts list won't show historic events
        };

        items.push(args);
    }
    return items;
}

/** Builds request based on the calendar, if there is no calendar, we are looking at a staff calendar */
export function buildAvailableShiftEntryListRequest(
    start: Moment,
    history: any
): AvailableShiftsRequestUi {
    const startOfMonth = start.clone().startOf("month");
    const endOfMonth = start.clone().endOf("month");
    history.push({
        search: `?month=${startOfMonth.unix()}`
    });
    return {
        startDate: startOfMonth.clone().subtract(8, "days").unix(),
        endDate: endOfMonth.clone().add(8, "days").unix(),
        calendarIds: []
    };
}

export function canUserSeeAvailableShiftsEntry(
    clinicalGrade: ClinicalGrade,
    requiredStaffSections: Array<AvailableShiftSection>
): boolean {
    for (const section of requiredStaffSections) {
        for (const group of section.groupings) {
            const groupClinicalGrades = group.clinicalGrades || [];
            if (groupClinicalGrades.length === 0) {
                return true;
            }

            const index = groupClinicalGrades.findIndex(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (grade: ClinicalGrade) => grade === clinicalGrade
            );

            if (index < 0) continue;
            // Found a clinical grade matching the user clinical grade and there is availability.
            if (group.availableSlotCount > 0) {
                return true;
            }
        }
    }
    return false;
}

export function canUserSeeSection(
    clinicalGrade: ClinicalGrade,
    section: AvailableShiftSection
): boolean {
    for (const group of section.groupings) {
        const groupClinicalGrades = group.clinicalGrades || [];
        if (groupClinicalGrades.length === 0) {
            return true;
        }

        const index = groupClinicalGrades.findIndex(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (grade: ClinicalGrade) => grade === clinicalGrade
        );

        //Can't find clinical grade in group
        if (index > -1) return true;
    }
    return false;
}

export function canUserSeeGroup(
    clinicalGrade: ClinicalGrade,
    group: AvailableShiftGrouping
): boolean {
    if (group.availableSlotCount === 0) {
        return false;
    }

    const groupClinicalGrades = group.clinicalGrades || [];
    if (groupClinicalGrades.length === 0) {
        return true;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const index = groupClinicalGrades.findIndex((grade: ClinicalGrade) => clinicalGrade === grade);

    if (index < 0) {
        return false;
    }
    return group.availableSlotCount > 0;
}
