import {Dispatch} from "redux";
import {CALENDAR_ENTRY_STORE_STATE, CalendarEntryDispatchTypes} from "./CalendarEntryActionTypes";
import {
    AssignmentState,
    AvailableShiftCalendarEntry,
    BillingType,
    Calendar,
    CalendarEntry,
    CalendarEntryState,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffAssignment,
    StaffAssignmentGrouping,
    StaffBlock,
    StaffBlockSection,
    Venue
} from "../../../api/grs";
import moment from "moment";
import store, {RootStore} from "../../Store";
import {getCalendar} from "../../calendar/actions/CalendarActions";
import {getVenuesForCalendarId} from "../../venueList/actions/VenueListActions";
import {nanoid} from "nanoid";
import {getAllMedicareStaffMembersComplex} from "../../staffList/actions/StaffListActions";
import GrsApiModel from "../../apiModel/GrsApiModel";
import deepCopy from "../../../utils/csvUtils";
import {getStaffBlockListFromService} from "../../globalStaffBlockList/actions/GlobalStaffBlockListActions";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithData,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {showErrorToast} from "../../../utils/toastUtils";
import {getGeoLocation} from "../../geolocation/single/actions/GeoLocationActions";

/** Removes stale date from the store. */
export const nullifyCalendarEntryStore = () => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** SERVICE */
/** saves the calendar entry */
export const saveCalendarEntry = (entry: EventCalendarEntry | FrontlineCalendarEntry) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                CALENDAR_ENTRY_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().saveCalendarEntry(processEntryOutgoing(entry)),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

function processEntryOutgoing(entry: EventCalendarEntry | FrontlineCalendarEntry) {
    const entryCopy = deepCopy(entry);
    for (const section of entryCopy.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                if (assignment.state === AssignmentState.Unassigned) {
                    assignment.staffMember = undefined;
                }
            }
        }
    }

    return entryCopy;
}

function processEntryIncoming(
    entry: EventCalendarEntry | FrontlineCalendarEntry | undefined | null,
    calendar: Calendar | null | undefined
) {
    if (!entry) return;
    const entryCopy = deepCopy(entry);
    for (const section of entryCopy.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                if (assignment.state === AssignmentState.Unassigned) {
                    assignment.staffMember = {
                        staffId: "",
                        staffName: "",
                        flags: [],
                        external: false
                    };
                }
            }
        }
    }

    if (!entryCopy.billingType && calendar) {
        entryCopy.billingType = calendar.billingType;
    }

    return entryCopy;
}

export const saveEntryWithRedux = (entry: EventCalendarEntry | FrontlineCalendarEntry) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        try {
            const request = await GrsApiModel.getCalendarApi().saveCalendarEntry(entry);
            if (request.status === 200) {
                dispatch({
                    type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
                    error: null,
                    loading: false,
                    data: request.data
                });
                return true;
            }

            return false;
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const fetchCalendarEntrySimple = (id: number) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                CALENDAR_ENTRY_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getCalendarEntryById(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Fetches calendar entry from service */
export const fetchCalendarEntry = (id: number) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>, state: () => RootStore) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllMedicareStaffMembersComplex({regions: []}));

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getStaffBlockListFromService());

            const entry = await getDataFromServiceWithData(
                CALENDAR_ENTRY_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getCalendarEntryById(id),
                statusCodeCallback
            );

            const calendar = state().calendar.data;
            const calendarId = calendar ? calendar.id : entry?.calendarId;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getVenuesForCalendarId(calendarId));

            const venue = state().venueList.data?.find((ven) => ven.id === entry?.venueId);

            //Should never hit this
            if (!venue) return;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getGeoLocation(venue.postcode));

            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: processEntryIncoming(entry, calendar)
            });
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Fetches calendar entry from service */
export const deleteCalendarEntryFromService = (id: number) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                CALENDAR_ENTRY_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().deleteCalendarEntry(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** LOCAL */
/** Updates the calendar entry */
export const setCalendarEntry = (entry: EventCalendarEntry | FrontlineCalendarEntry) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: entry
        });
        return state().calendarEntry.data;
    };
};

export const getLocalCalendarEntry = () => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>, state: () => RootStore) => {
        const entry = state().calendarEntry.data;
        if (!entry) return;
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: entry
        });
        return entry;
    };
};

/** Adds a staff block section to calendar entry */
export const addStaffBlockSection = (name: string) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });
        const entry: EventCalendarEntry | FrontlineCalendarEntry | undefined | null =
            store.getState().calendarEntry.data;

        if (!entry) return;

        // Create new section and add it to the required staff block
        const newSection: StaffBlockSection = {
            id: nanoid(),
            name,
            groupings: Array<StaffAssignmentGrouping>()
        };

        entry.requiredStaff.sections.push(newSection);

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Adds a staff block section to calendar entry */
export const setStaffBlockSection = (section: StaffBlockSection) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });
        const entry: EventCalendarEntry | FrontlineCalendarEntry | undefined | null =
            store.getState().calendarEntry.data;

        if (!entry) return;

        //Find section that was altered
        const index = entry.requiredStaff.sections.findIndex(
            (el: StaffBlockSection) => el.id === section.id
        );
        // Section doesn't exist.
        if (index < 0) return;

        //Update the section.
        entry.requiredStaff.sections[index] = section;

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Removes a staff block section to calendar entry */
export const removeStaffBlockSection = (sectionId: string) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });
        const entry: EventCalendarEntry | FrontlineCalendarEntry | undefined | null =
            store.getState().calendarEntry.data;

        if (!entry) return;

        //Find section that wants to be deleted.
        const index = entry.requiredStaff.sections.findIndex(
            (el: StaffBlockSection) => el.id === sectionId
        );
        // Section doesn't exist.
        if (index < 0) return;

        //Delete the section.
        entry.requiredStaff.sections.splice(index, 1);

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Adds a new group to a section */
export const addStaffAssignmentGrouping = (args: AddStaffAssignmentGroupingArgs) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const entry = store.getState().calendarEntry.data;
        if (!entry) return;

        const section = entry.requiredStaff.sections.find(
            (x: StaffBlockSection) => x.id === args.sectionId
        );
        if (!section) return;

        section.groupings.push(args.group);

        const updatedSections = entry.requiredStaff.sections.map((el: StaffBlockSection) =>
            el.id === section.id ? section : el
        );

        const updatedEntry: EventCalendarEntry | FrontlineCalendarEntry = {
            ...entry,
            requiredStaff: {
                sections: updatedSections
            }
        };

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: updatedEntry
        });
    };
};

/** Updates a group in a section */
export const setStaffAssignmentGrouping = (grouping: StaffAssignmentGrouping) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const entry = store.getState().calendarEntry.data;
        if (!entry) return;

        for (const section of entry.requiredStaff.sections) {
            const index = section.groupings.findIndex(
                (x: StaffAssignmentGrouping) => x.id === grouping.id
            );
            if (index >= 0) {
                section.groupings[index] = grouping;
                break;
            }
        }

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Removes a group in a section */
export const removeStaffAssignmentGrouping = (groupId: string) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const entry = store.getState().calendarEntry.data;
        if (!entry) return;

        for (const section of entry.requiredStaff.sections) {
            const index = section.groupings.findIndex(
                (x: StaffAssignmentGrouping) => x.id === groupId
            );
            if (index >= 0) {
                section.groupings.splice(index, 1);
                break;
            }
        }

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Adds an assignment to a group in an entry */
export const addStaffAssignment = (groupId: string) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const entry = store.getState().calendarEntry.data;
        if (!entry) return;

        // Create blank assignment
        const assignment: StaffAssignment = {
            id: nanoid(),
            state: AssignmentState.Unassigned,
            rate: 0
        };

        for (const section of entry.requiredStaff.sections) {
            const index = section.groupings.findIndex(
                (x: StaffAssignmentGrouping) => x.id === groupId
            );

            //If we can't find the group to add to, continue going over the rest of the groups
            if (index < 0) continue;

            section.groupings[index].assignments.push(assignment);
            break;
        }

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Updates an assignment to a group in an entry */
export const setStaffAssignment = (assignment: StaffAssignment) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const entry = store.getState().calendarEntry.data;
        if (!entry) return;

        let found = false;
        for (const section of entry.requiredStaff.sections) {
            for (const grouping of section.groupings) {
                const index = grouping.assignments.findIndex(
                    (x: StaffAssignment) => x.id === assignment.id
                );
                if (index >= 0) {
                    found = true;
                    grouping.assignments[index] = assignment;
                    break;
                }
            }

            if (found) break;
        }

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Updates an assignment to a group in an entry */
export const removeStaffAssignment = (assignmentId: string) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const entry = store.getState().calendarEntry.data;
        if (!entry) return;

        let found = false;
        for (const section of entry.requiredStaff.sections) {
            for (const grouping of section.groupings) {
                const index = grouping.assignments.findIndex(
                    (x: StaffAssignment) => x.id === assignmentId
                );
                if (index >= 0) {
                    found = true;
                    //Reset back to blank instead of deleting entire square
                    grouping.assignments.splice(index, 1);
                    break;
                }
            }
            if (found) break;
        }

        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: entry
        });
    };
};

/** Creates a new calendar entry */
export const createNewCalendarEntry = (calendarId: number, startDate: number) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        try {
            //Manually set this as this call isn't going through the service wrappers for this calendar entry action
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.LOADING,
                loading: true,
                error: null
            });

            //Get all staff members for assignment purposes
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllMedicareStaffMembersComplex({regions: []}));

            //Get the calendar by its Id for the entry
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const calendar: Calendar = await store.dispatch(getCalendar(calendarId));

            //Separate promises as they're part of the same system but separate APIs
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const venues: Venue[] = await store.dispatch(getVenuesForCalendarId(calendar.id));

            const firstVenue = venues.length > 0 ? venues[0] : undefined;

            if (!firstVenue) {
                showErrorToast(
                    "Venues do not exists for this calendar. Please create a venue to continue!"
                );
                return;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getGeoLocation(firstVenue.postcode));

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getStaffBlockListFromService());

            const entry = createEntryForCalendarType(calendar, startDate, firstVenue.id);

            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: processEntryIncoming(entry, calendar)
            });
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Creates a new blank calendar entry based on calendar type */
function createEntryForCalendarType(
    calendar: Calendar,
    startDate: number,
    venueId: number
): FrontlineCalendarEntry | EventCalendarEntry {
    switch (calendar.calendarType) {
        case CalendarTypeEnum.Event:
            return createBlankEventCalendarEntry(
                calendar.id,
                venueId,
                calendar.calendarType,
                calendar.staffBlock,
                startDate,
                calendar.billingType
            );

        case CalendarTypeEnum.Frontline:
        case CalendarTypeEnum.OnCall:
            return createBlankFrontLineCalendarEntry(
                calendar.id,
                venueId,
                calendar.calendarType,
                calendar.staffBlock,
                startDate,
                calendar.billingType
            );
    }
}

/** Creates a blank FrontLineCalendarEntry */
export function createBlankFrontLineCalendarEntry(
    calendarId: number,
    venueId: number | undefined,
    type: CalendarTypeEnum,
    staffBlock: StaffBlock,
    startDate: number,
    billingType: BillingType
): FrontlineCalendarEntry {
    return {
        id: 0,
        version: 0,
        calendarType: type,
        state: CalendarEntryState.Active,
        startDate: moment.unix(startDate).startOf("day").unix(),
        endDate: moment.unix(startDate).startOf("day").unix(),
        actualStartDate: undefined,
        actualEndDate: undefined,
        description: "Call Sign TBC",
        notes: "",
        requiredStaff: staffBlock,
        calendarId,
        venueId,
        billingType
    };
}

/** Creates a blank EventCalendarEntry */
export function createBlankEventCalendarEntry(
    calendarId: number,
    venueId: number | undefined,
    type: CalendarTypeEnum,
    staffBlock: StaffBlock,
    startDate: number,
    billingType: BillingType
): EventCalendarEntry {
    return {
        id: 0,
        calendarId,
        venueId, //can't be 0
        version: 0,
        calendarType: type,
        state: CalendarEntryState.Active,
        startDate: moment.unix(startDate).startOf("day").unix(),
        endDate: startDate,
        requiredStaff: staffBlock,
        actualStartDate: undefined,
        actualEndDate: undefined,
        description: "New Calendar Entry",
        notes: "",
        depotTime: startDate,
        venueTime: startDate,
        billingType
    };
}

/** Checks to see if the calendar entry is an EventCalenderEntry */
export function isEventCalendarEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry | null | undefined
): entry is EventCalendarEntry {
    if (!entry) return false;
    return "venueTime" in entry;
}

/** Checks to see if the calendar entry is an FrontlineCalendarEntry */
export function isFrontlineCalendarEntry(
    entry: FrontlineCalendarEntry | any
): entry is FrontlineCalendarEntry {
    if (!entry) return false;
    return "startDate" in entry;
}

/** Allows for new group to be added to a section in a calendar entry */
export interface AddStaffAssignmentGroupingArgs {
    sectionId: string;
    group: StaffAssignmentGrouping;
}

export function isCalendarEntry(
    entry: CalendarEntry | AvailableShiftCalendarEntry | null | undefined
): entry is CalendarEntry {
    if (!entry) return false;
    return "version" in entry;
}
