import React from "react";
import {AssignmentState, CalendarTypeEnum, StaffPendingAction} from "../../../../api/grs";
import {toArray} from "../../../../utils/sortingUtils";
import {formatUnixToddddDoMMMMYYYY} from "../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {PulseTable} from "pulse_table";
import ShiftActions from "../../../Notifications/ShiftActions";
import {useUserData} from "../../../Hooks/useUserData";
import {useDispatch} from "react-redux";
import {getPendingNotifications} from "../../../../store/pendingNotifications/actions/PendingNotificationsActions";

const NotificationListTable = (props: StaffPendingAction[]) => {
    const user = useUserData();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <PulseTable
                    items={toTableRow(toArray(props))}
                    headers={{
                        shift: "Shift",
                        calendar: "Calendar",
                        date: "Date",
                        state: "State",
                        actions: "Actions"
                    }}
                    customRenderers={{
                        actions: (item: TableRow) => (
                            <React.Fragment>
                                <ShiftActions
                                    {...item.actions}
                                    staffId={user.username}
                                    callback={() => {
                                        dispatch(getPendingNotifications());
                                    }}
                                />
                            </React.Fragment>
                        )
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    There are no outstanding notifications to address
                                </h5>
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default NotificationListTable;

interface TableRow {
    shift: string;
    calendar: string;
    date: string;
    state: string;
    actions: TableRowActions;
}

interface TableRowActions {
    entryId: number;
    state: AssignmentState;
}

function toTableRow(pendingActions: StaffPendingAction[]): TableRow[] {
    return pendingActions.map((item) => {
        return {
            shift: getShiftName(item),
            calendar: item.calendarName,
            date: formatUnixToddddDoMMMMYYYY(item.startDate),
            state: getUiFriendlyText(item.currentState),
            actions: {
                entryId: item.entryId,
                state: item.currentState
            }
        };
    });
}

function getShiftName(pendingAction: StaffPendingAction): string {
    switch (pendingAction.calendarType) {
        case CalendarTypeEnum.Frontline:
        case CalendarTypeEnum.OnCall:
            return pendingAction.entryName;
        case CalendarTypeEnum.Event:
            return pendingAction.venueName ?? "New Calendar Entry";
    }
}
